// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// As per https://discuss.rubyonrails.org/t/turbolinks-broken-by-default-with-a-secure-csp/74790
document.addEventListener("turbolinks:request-start", function(event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop('content'));
});

document.addEventListener("turbolinks:before-cache", function() {
  $('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce)
  });
});

// localtime
import LocalTime from 'local-time';
window.LocalTime = LocalTime;
LocalTime.start();

window.debounce = (duration, f) => {
  let timeout = null;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      f(...args);
    }, duration);

    return timeout;
  }
}

// Modified from https://stackoverflow.com/a/73835123
window.throttle = (ms, fn) => {
  let locked = false;

  return (...args) => {
    if (!locked) {
      fn(...args);
      locked = true;

      setTimeout(() => {
        locked = false;
      }, ms);
    }
  }
}

// From https://stackoverflow.com/a/7557433
window.isElementInViewport = el => {
  // Special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  let rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

// 'i' as in 'interpolate'.
// Use this *anytime* you want to interpolate user input!
// Slight modification of https://github.com/janl/mustache.js/blob/master/mustache.js#L60
window.i = input => {
  let toBeEscaped = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;'
  };

  return String(input).replace(/[&<>"'`=\/]/g, s => {
    return toBeEscaped[s];
  });
};

window.createTurndown = () => {
  let td = new TurndownService({ emDelimiter: '*' });

  td.addRule('sourcedBlockquote', {
    // TODO: Check that element is a blockquote?
    filter: el => el.tagName === 'BLOCKQUOTE' && el.dataset.hasOwnProperty('source'),
    replacement: (content, el) => {
      content = content.trim().replace(/^/gm, '> ');

      let attrs = Object.keys(el.dataset)
        .map(k => `> % ${k}: ${el.dataset[k]}`)
        .join('\n');

      return `${attrs}
>
${content}`;
    }
  })
  // Modification of https://github.com/mixmark-io/turndown/issues/357#issuecomment-1694758857.
  // It's inspired by the original implementation but fixes a bug where first-child block elements
  // wouldn't be parsed right, and accommodates data-step attributes.
  .addRule('listItem', {
    filter: 'li',
    replacement: (content, node, options) => {
      content = content
        .replace(/^\n+/, '') // remove leading newlines
        .replace(/\n+$/, '\n') // replace trailing newlines with just a single one
        .replace(/\n/gm, '\n    '); // indent

      let prefix = options.bulletListMarker + '   ';
      let parent = node.parentNode;

      // Check if the first child is a block-level element
      let blockElements = [
        'address', 'article', 'aside', 'blockquote', 'canvas', 'dd', 'div',
        'dl', 'dt', 'fieldset', 'figcaption', 'figure', 'footer', 'form',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hr', 'li', 'main',
        'nav', 'noscript', 'ol', 'p', 'pre', 'section', 'table', 'tfoot',
        'ul', 'video'
      ].map(t => t.toUpperCase());

      let firstChildIsBlock = node.firstChild && blockElements.includes(node.firstChild.nodeName);

      if (parent.nodeName === 'OL') {
        let index;

        if (node.dataset.hasOwnProperty('step')) {
          index = node.dataset.step;
          prefix = index + '.  ';
        } else {
          let start = parent.getAttribute('start');

          index = Array.prototype.indexOf.call(parent.children, node);
          prefix = (start ? Number(start) + index : index + 1) + '.  ';
        }
      }

      return prefix + (firstChildIsBlock ? '\n\n    ' : '') + content + (node.nextSibling && !/\n$/.test(content) ? '\n' : '');
    }
  });

  return td;
};

$(document).on('paste', '.paste-markdown', e => {
  e = e.originalEvent;

  // execCommand is deprecated but the clipboard API
  // does not offer adequate replacement for inserting
  // text without breaking undo/redo functionality.
  // If execCommand is available, we use it.
  if (!document.execCommand) {
    return;
  }

  let html = e.clipboardData.getData('text/html');
  let pastedValue;

  if (html) {
    e.preventDefault();

    let template = document.createElement('template');

    template.innerHTML = html;

    // Other sites may have elements of class .blockquote-footer
    // Using this special class ensures that we only remove
    // blockquote footers from our own site.
    $(template.content).find('.blockquote-footer-372ca3ad7c2fd8c9eeae2affea132d23').remove();

    let td = window.createTurndown();
    pastedValue = td.turndown(template.innerHTML);

    document.execCommand('insertText', false, pastedValue);
  }
});
