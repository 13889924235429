import consumer from "./consumer"

window.subscribeToDiscussion = (id, isParticipant) => {
  let typing = {};
  let audio = new Audio('/assets/message-in.m4a');
  let formButton;

  if (isParticipant) {
    formButton = document.getElementById('message_submit');
  }

  window.onfocus = () => {
    if (document.title.startsWith('(*) ')) {
      document.title = document.title.slice(4);
    }
  };

  let displayTyping = () => {
    let typingIndicator = document.getElementById('typing-indicator');
    let names = Object.keys(typing);
    let text;

    if (names.length) {
      if (names.length >= 3) {
        text = names.length + ' people are typing...';
      }
      else if (names.length == 2) {
        text = names.join(', ') + ' are typing...';
      } else {
        text = names[0] + ' is typing...';
      }
    } else {
      text = '';
    }

    typingIndicator.innerText = text;
  }

  let addTyper = typer => {
    typing[typer] = window.debounce(2000, () => {
      removeTyper(typer);
      displayTyping();
    });
  };

  let removeTyper = typer => {
    delete typing[typer];
  };

  return consumer.subscriptions.create({ channel: "DiscussionChannel", id }, {
    // Called when the subscription is ready for use on the server
    connected() {
      if (!isParticipant) {
        return;
      }

      formButton.value = 'Send public message';
      formButton.disabled = false;
    },

    // Called when the subscription has been terminated by the server
    disconnected() {
      if (!isParticipant) {
        return;
      }

      formButton.value = 'Connection lost. Reconnecting...';
      formButton.disabled = true;
    },

    // Called when there's incoming data on the websocket for this channel
    received(data) {
      switch (data.type) {
        case 'message': {
          let discussion = document.getElementById('discussion');
          let previousDraft;
          let me;

          if (isParticipant) {
            previousDraft = window.previousDraft();
            me = document.getElementById('message_from').value;
          }

          if (previousDraft) {
            previousDraft.insertAdjacentHTML('beforebegin', data.content.html);
          } else {
            discussion.insertAdjacentHTML('beforeend', data.content.html);
            window.scrollTo(0, document.body.scrollHeight);
          }

          if (data.content.from != me) {
            audio.play();

            // TODO: Tracking whether the *window* has focus would be better.
            // document.hasFocus returns false when the cursor is, say, in the URL bar.
            if (!document.hasFocus() && !document.title.startsWith('(*) ')) {
              // Show in title that there are unread messages
              document.title = '(*) ' + document.title;
            }
          }

          if (typing[data.content.from]) {
            removeTyper(data.content.from);
            displayTyping();
          }

          break;
        }
        case 'typing': {
          let participant = data.content.typing;
          let myName = isParticipant ? document.getElementById('message_from').value : '';

          if (participant == myName) {
            break;
          }

          if (!typing[participant]) {
            addTyper(participant);
          }

          typing[participant]();
          displayTyping();

          break;
        }
      }
    },

    typing(participant) {
      if (isParticipant) {
        this.send({ type: 'typing', content: { typing: participant } });
      }
    }
  });
};
